





















































































































































































































import { SearchBuilder } from "@/builder";
import CreateAccessoryModal from "@/components/Accessory/CreateAccessoryModal.vue";
import SelectExpedition from "@/components/custom/select/SelectExpedition.vue";
import SelectRack from "@/components/custom/select/SelectRack.vue";
import { VNodes } from "@/components/VNodes";
import quantityFilter from "@/filters/quantity.filter";
import { debounce } from "@/helpers/debounce";
import { useAsset, useMinByDate, useValidateMaximumFileSize } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  CreateAssetTransferFormState,
  CreateAssetTransferLine,
  CreateAssetTransferRequestDto,
} from "@/models/interface/asset-transfer";
import {
  AddressDataDto,
  ListContactDataDto,
} from "@/models/interface/contact-data";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { assetTransferService } from "@/services/asset-transfer.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { UploadFile } from "ant-design-vue/types/upload";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({
  components: {
    SelectRack,
    SelectExpedition,
    CreateAccessoryModal,
    VNodes,
  },
})
export default class CreateAssetTransfer extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formRef")
  formRef!: FormModel;

  formData: CreateAssetTransferFormState = {
    from: undefined,
    to: undefined,
    date: moment(),
    expedition: undefined,
    expeditionAddress: undefined,
    attachment: "",
    truckNumber: "",
    driverNameAndPhoneNumber: "",
    notes: "",
    assetTransferLines: [],
  };

  formRules = {
    from: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    to: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    date: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    expedition: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    expeditionAddress: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    truckNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    driverNameAndPhoneNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    notes: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: "64px",
      customRender: (no: number) => `${no}.` || "-",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_quantity"),
      dataIndex: "quantity",
      customRender: (qty: number) => quantityFilter(qty),
    },
    {
      title: this.$t("lbl_list_accessories"),
      dataIndex: "accessories",
      width: "200px",
      scopedSlots: { customRender: "listAccessories" },
    },
  ];

  loading = {
    createAssetTransfer: false,
    unitCode: false,
    uploadAttachment: false,
  };

  modal = {
    visible: false,
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  };

  fileList: UploadFile[] = [];
  selectedRowKeys: number[] = [];
  expeditionAddressOptions: Option<AddressDataDto>[] = [];
  unitCodeOptions: Option<AssetResponseDto>[] = [];
  accessoryOptions: Option<string>[] = [];
  attachments: UploadFile[] = [];

  created(): void {
    this.loading.unitCode = true;
    this.fetchUnitCodeOptions(new RequestQueryParams())
      .then(res => (this.unitCodeOptions = res))
      .finally(() => (this.loading.unitCode = false));

    this.fetchAccessories();
  }

  //#region network requests
  async fetchUnitCodeOptions(
    params: RequestQueryParamsModel
  ): Promise<Option<AssetResponseDto>[]> {
    const { findAllAsset, toOptionsNew } = useAsset();
    const builder = new SearchBuilder();
    const searchQuery = builder
      .push(["status", "READY"])
      .or()
      .push(["status", "INACTIVE"])
      .or()
      .push(["status", "NEED_TO_REPAIR"])
      .build();

    if (params.search) {
      params.search = [params.search, searchQuery].join(builder.AND);
    } else {
      params.search = searchQuery;
    }

    const response = await findAllAsset(params);
    return toOptionsNew(response.data);
  }

  fetchAccessories(): void {
    salesOrderServices.getListAccessories().then(data => {
      this.accessoryOptions = data.map((accessory, index) => ({
        key: index,
        label: accessory,
        value: accessory,
      }));
    });
  }

  createAssetTransfer(): void {
    const dto: CreateAssetTransferRequestDto =
      this.mapFormDataToCreateAssetTransferDto(this.formData);

    this.loading.createAssetTransfer = true;
    assetTransferService
      .createAssetTransfer(dto)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.handleBack();
      })
      .finally(() => (this.loading.createAssetTransfer = false));
  }
  //#endregion network requests

  //#region utilities
  recalculateRowNumbers(): void {
    this.formData.assetTransferLines.forEach((data, index) => {
      data.no = index + 1;
    });
  }

  mapFormDataToCreateAssetTransferDto(
    formData: CreateAssetTransferFormState
  ): CreateAssetTransferRequestDto {
    const dto: CreateAssetTransferRequestDto = {
      date: formData.date!.format(),
      driverName: formData.driverNameAndPhoneNumber,
      expeditionAddress: formData.expeditionAddress!,
      expeditionId: formData.expedition!,
      fromId: formData.from!.key,
      note: formData.notes,
      toId: formData.to!.key,
      truckNumber: formData.truckNumber,
      url: formData.attachment,
      units: formData.assetTransferLines.map(line => ({
        unitId: line.unitCode!.key,
        listAccessories: line.listAccessories,
      })),
    };

    return dto;
  }

  beforeToday(curr: Moment): boolean {
    return useMinByDate(curr, moment());
  }
  //#endregion utilities

  //#region event handler methods
  handleAddRow(): void {
    const row: CreateAssetTransferLine = {
      equipment: "",
      listAccessories: [],
      model: "",
      no: this.formData.assetTransferLines.length + 1,
      quantity: 0,
      serialNumber: "",
      specification: "",
      type: "",
      unitCode: undefined,
      unitCodeOptions: [],
      loadingUnitCodeOptions: false,
    };

    this.formData.assetTransferLines.push(row);

    this.recalculateRowNumbers();
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.formData.assetTransferLines =
        this.formData.assetTransferLines.filter(
          line => !this.selectedRowKeys.includes(line.no)
        );

      this.selectedRowKeys = [];
      this.recalculateRowNumbers();
    });
  }

  handleBack(): void {
    this.$router.push({ name: "asset.transfers" });
  }

  handleSubmit(): void {
    this.formRef.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      for (const line of this.formData.assetTransferLines) {
        if (!line.unitCode) {
          this.showNotifWarning("notif_validation_error");
          return;
        }
      }

      this.createAssetTransfer();
    });
  }

  handleSelectedTableRowChange(keys: number[]): void {
    this.selectedRowKeys = keys;
  }
  //#endregion event handler methods

  //#region select option methods
  handleExpeditionChange(expedition?: ListContactDataDto): void {
    this.formData.expeditionAddress = undefined;

    if (!expedition) {
      return;
    }

    const options: Option<AddressDataDto>[] = [];
    expedition.addressDataList.forEach((address, index) => {
      if (!address.billTo) {
        return;
      }

      options.push({
        key: index,
        label: address.address,
        value: address.address,
        meta: address,
      });
    });
    this.expeditionAddressOptions = options;
  }

  handleUnitCodeChange(
    unitCode: LabelInValue | undefined,
    record: CreateAssetTransferLine
  ): void {
    let assetOption: Option<AssetResponseDto> | undefined =
      this.unitCodeOptions.find(option => option.key === unitCode?.key);
    if (!assetOption) {
      assetOption = record.unitCodeOptions.find(
        option => option.key === unitCode?.key
      );
    }

    const asset = assetOption?.meta;
    if (!asset) {
      record.equipment = "-";
      record.model = "-";
      record.serialNumber = "-";
      record.specification = "-";
      record.type = "-";
      record.quantity = 0;
      return;
    }

    const assetCategoryIdSegments = asset.assetCategory.id.split(".");
    record.equipment = assetCategoryIdSegments[0]?.toUpperCase();
    record.model = asset.model;
    record.serialNumber = asset.serialNumber;
    record.specification = asset.description;
    record.type = asset.type;
    record.quantity = asset.unit;
  }

  handleSearch(val = "", record: CreateAssetTransferLine): void {
    const { filterBy } = useAsset();

    debounce(() => {
      const params = new RequestQueryParams();
      params.search = filterBy({ unitCode: val });
      record.loadingUnitCodeOptions = true;
      this.fetchUnitCodeOptions(params)
        .then(res => {
          record.unitCodeOptions = res;
        })
        .finally(() => (record.loadingUnitCodeOptions = false));
    });
  }
  //#endregion select option methods

  //#region file uploader methods
  async handleUploadAttachment({ file }: { file: File }): Promise<void> {
    try {
      this.loading.uploadAttachment = true;
      const attachments = [...this.attachments];
      this.attachments = attachments.slice(-1);

      const fd = new FormData();
      fd.append("file", file);

      const response = await assetTransferService.uploadAttachment(fd);
      this.formData.attachment = response.url;
      this.attachments[0].url = response.url;

      this.showNotifSuccess("notif_file_upload_successfully", {
        filename: file.name,
      });
    } catch (error) {
      this.formData.attachment = "";
      this.showNotifError("notif_file_upload_failed", {
        filename: file.name,
      });
    } finally {
      this.loading.uploadAttachment = false;
    }
  }

  handleBeforeUpload(file: UploadFile) {
    const isValid = this.validationFileSize(file);
    if (!isValid) return false;

    this.attachments.push(file);

    return true;
  }

  validationFileSize(file: UploadFile): boolean {
    if (useValidateMaximumFileSize(file.size)) {
      this.showNotifWarning("attachment.allow-size");
      return false;
    }

    return true;
  }

  handleRemoveAttachment(): boolean {
    this.attachments.splice(0, 1);
    this.formData.attachment = "";
    return true;
  }
  //#endregion
}
